<template>
  <div class="c-app flex-row align-items-center mainbg">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4 login-lft">
              <CCardBody>
                <CForm>
                  <CRow>
                    <img
                    src="img/logo/logo.png"
                    style="height:70px;"
                    />
                  <img
                    src="img/logo/fraudavoid_ logo.png"
                    style="height:70px; width:180px;"
                  />
                  </CRow>
                  <h4 class="mt-2">Restore Your Password</h4>
                  <CInput
                    placeholder="Email"
                    autocomplete="Email"
                    v-model="input.email"
                  >
                    <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                  </CInput>
                  
                  <CRow>
                    <CCol class="text-left">
                      <CButton color="link" class="px-0" @click="gotoPage('login')">Go to Login</CButton>
                    </CCol>
                    <CCol class="text-right">
                      <CButton color="link" class="d-lg-none" @click="gotoPage('register')">Register now!</CButton>
                    </CCol>
                  </CRow>
                  <CRow class="text-center mt-2">
                    <CCol>
                      <CButton color="primary" class="px-5" @click="MailPassword()">Send</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none login-rgt"
              body-wrapper
            >
              <CCardBody>
                <img
                    src="img/logo/logo.png"
                    style=""
                  />
                
                <p>Glad to see you again
                While you were away fraudAvoid has been monitoring your traffic for fraudulent patterns and making sure you see if your revenues are at risk</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                   @click="gotoPage('register')"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import LoginService from './../../services/loginControlService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  name: 'Login',
  data() {
            return {
                input: {
                    email: "",
                }
            }
        },
      mounted(){
        if(window.localStorage.getItem('user')&& window.localStorage.getItem('user') != 'null'){
          this.$router.push('/dashboard')
        }
      },
        methods: {
          gotoPage(txt){
            switch(txt){
              case 'register' : this.$router.push("/pages/register")
              break;
              case 'forgotpassword' : this.$router.push("/pages/forgotpassword")
              break;
              case 'login': this.$router.push("/pages/login")
              break
            }
           },
         async MailPassword() {
            try {
              if(this.input.email == ""){
                Swal.fire({title: 'Error!',text: "please enter email",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              }
              else{
              let response
                try {
                  response = await LoginService.forgetpwd(this.input)
                } catch(e){
                  console.error("Error in login")
                  console.log(e)
                }
                console.log(response)
              if (response.result == 'true') {
                Swal.fire({title: 'Success!',text: "Please check your mail for reset password",icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
                // alert("Incorrect Username or Password")
              }
              else {
               Swal.fire({title: 'Error!',text: "Please enter registered Mail Id",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              }}
            }catch(e){
              // Swal.fire({title: 'Error!',text: "connection error'",icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
              // this.$router.push('Login')
            }
            },
         
        }
}
</script>
